import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = [
    "mainInput",
    "selectedTags",
    "searchWrapper",
    "searchInput",
    "searchResults",
  ];

  connect() {
    this.selectedTags = this.mainInputTarget.value.split(",");

    this.hideMainInput();
    this.createListOfSelectedTags();
    this.createSearchComponents();
    this.showSelectedTags();

    this.onSearchQueryChange = debounce(this.onSearchQueryChange, 500);
    this.searchInputTarget.addEventListener("input", this.onSearchQueryChange);
  }

  disconnect() {
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.removeEventListener(
        "input",
        this.onSearchQueryChange
      );
    }
  }

  hideMainInput() {
    this.mainInputTarget.setAttribute("type", "hidden");
  }

  createListOfSelectedTags() {
    if (this.hasSelectedTagsTarget) {
      return;
    }

    const selectedTags = document.createElement("ul");
    selectedTags.setAttribute("class", "tags selected");
    selectedTags.setAttribute("data-tags-target", "selectedTags");
    this.mainInputTarget.after(selectedTags);
  }

  createSearchComponents() {
    if (this.hasSearchWrapperTarget) {
      return;
    }

    // Search wrapper
    const searchWrapper = document.createElement("div");
    searchWrapper.setAttribute("data-tags-target", "searchWrapper");
    this.selectedTagsTarget.after(searchWrapper);

    // Search input
    const searchInput = document.createElement("input");
    searchInput.setAttribute("type", "text");
    searchInput.setAttribute("placeholder", "Start typing to search tags...");
    searchInput.setAttribute("class", "form-control");
    searchInput.setAttribute("data-tags-target", "searchInput");
    searchInput.setAttribute(
      "data-action",
      [
        "keydown.enter->tags#onSearchEnter",
        "keydown.esc->tags#onSearchExit",
      ].join(" ")
    );
    searchWrapper.appendChild(searchInput);

    // Search results
    const searchResults = document.createElement("ul");
    searchResults.setAttribute("class", "tags search form-control");
    searchResults.setAttribute("data-tags-target", "searchResults");
    searchResults.style.display = "none"; // hide search results by default
    searchWrapper.appendChild(searchResults);
  }

  showSelectedTags() {
    this.selectedTagsTarget.innerHTML = "";

    for (const tag of this.selectedTags) {
      const xSpan = document.createElement("span");
      xSpan.setAttribute("data-action", "click->tags#onRemoveTag");

      const tagElement = document.createElement("li");
      tagElement.appendChild(document.createTextNode(tag));
      tagElement.appendChild(xSpan);
      this.selectedTagsTarget.appendChild(tagElement);
    }
  }

  showSearchResults(tags) {
    this.searchResultsTarget.innerHTML = "";
    this.searchResultsTarget.style.display = "block";

    for (const tag of tags) {
      const tagElement = document.createElement("li");
      tagElement.setAttribute("data-action", "click->tags#onAddTag");
      tagElement.appendChild(document.createTextNode(tag.title));
      this.searchResultsTarget.appendChild(tagElement);
    }
  }

  // Actions & listeners
  onSearchQueryChange = async () => {
    const query = this.searchInputTarget.value.trim();
    if (!query || !query.length) {
      return;
    }

    const matchingTags = await this.fetchTags("/cms/tags/search", query);
    this.showSearchResults(matchingTags);
  };

  onSearchEnter(event) {
    event.preventDefault();
    const tag = this.searchInputTarget.value.trim();
    this.addTag(tag);
  }

  onSearchExit(event) {
    event.preventDefault(); // prevent exit from full-screen mode
    this.cleanUpSearch();
  }

  onAddTag(event) {
    const tag = event.target.textContent;
    this.addTag(tag);
  }

  onRemoveTag(event) {
    const tag = event.target.parentElement.textContent;

    this.selectedTags = this.selectedTags.filter(
      (existingTag) => existingTag !== tag
    );
    this.refreshSelectedTags();
  }

  // Networking
  fetchTags = async (path, query) => {
    const url = new URL(path, window.location.href);
    const params = new URLSearchParams({ query: query });
    url.search = params.toString();

    const response = await fetch(url, {
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`);
    }

    const json = await response.json();
    const tags = json?.tags;
    if (!tags) {
      throw new Error(`There are no "tags" in response!`);
    }

    return tags;
  };

  // Helpers
  addTag(tag) {
    if (this.selectedTags.includes(tag)) {
      this.cleanUpSearch();
      return;
    }

    this.selectedTags.push(tag);
    this.refreshSelectedTags();
    this.cleanUpSearch();
  }

  refreshSelectedTags() {
    this.mainInputTarget.value = this.selectedTags.join(",");
    this.showSelectedTags();
  }

  cleanUpSearch() {
    this.searchInputTarget.value = "";
    this.searchInputTarget.blur();
    this.searchResultsTarget.style.display = "none";
  }
}
