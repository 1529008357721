import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const contentWidth = this.element.offsetWidth;
    const videoRatio = 16 / 9;
    const videoHeight = contentWidth / videoRatio;

    // Modify size of YouTube iframes
    this.element
      .querySelectorAll(
        "div.embed iframe[src^='https://www.youtube.com/embed']"
      )
      .forEach(function (iframe) {
        iframe.width = `${contentWidth}px`;
        iframe.height = `${videoHeight}px`;
      });
  }
}
